import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import Select from 'react-select';
import { useState, FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { DefaultTheme, useTheme } from 'styled-components';
import { showPageLoading } from '../../../actions/coreAction';
import {
  PLAID_GENERATE_TOKEN,
  USER_SOCIAL_LINK,
} from '../../../config/default';
import PageContainer from '../../shared/PageContainer';
import Overview from '../../shared/Sections/MyVerdeRevamp/Overview';
import AssetClassification from '../../shared/Sections/MyVerdeRevamp/AssetClassification';
import { getSelectStyles } from '../../shared/Sections/DefaultFiltersNew/default-filters';
import { Auth } from 'aws-amplify';
import WrappedAxios from '../../../utils/WrappedAxios';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import MobileSummary from '../../shared/Sections/MyVerdeRevamp/MobileSummary';
import TopProfile from '../../shared/Sections/MyVerdeRevamp/TopProfile';
import { useAuth } from '../../hocs/AuthProvider';
import StockInformation from '../../shared/Sections/MyVerdeRevamp/Overview/PortfolioPerformance/PerformanceChart';
import PortfolioPerformance from '../../shared/Sections/MyVerdeRevamp/Overview/PortfolioPerformance';
import PortfolioModal  from './Modal/PortfolioModal';

const PageContainerW = PageContainer;

const MyVerdeRevamp = ({
  darkMode,
  showPageLoading,
  allData,
  data,
  profileData,
}) => {
  const mainTabs = [
    {
      title: 'Portfolio',
    },
    {
      title: 'Performance',
    },
    {
      title: 'Allocation',
    },
  ];

  const { authUser } = useAuth();
  const currentTheme = useTheme();
  const [tabSelected, setTabSelected] = useState(mainTabs[0].title);
  const selectStyles = getSelectStyles(currentTheme.isDark);
  const [showPortfolioSummary, setShowPortfolioSummary] = useState(true);
  const [insightsData, setInsightsData] = useState([]);
  const [allPerformanceData, setAllPerformanceData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [assetData, setAssetData] = useState({});
  const [allAssetData, setAllAssetData] = useState({});
  const [portfolios, setPortfolios] = useState([]);
  const [currentPortfolioLabel, setCurrentPortfolioLabel] = useState([]);
  const [currentPortfolioValue, setCurrentPortfolioValue] = useState([]);
  const [currentPortfolioInsightKey, setCurrentPortfolioInsightKey] = useState(
    ''
  );

  function performance() {
    if (
      performanceData.length === 0 ||
      currentPortfolioInsightKey === '' ||
      allPerformanceData.length === 0
    ) {
      return [];
    }
    return currentPortfolioInsightKey !== 'all_portfolios'
      ? performanceData.filter(
          (elem) => elem[currentPortfolioInsightKey] !== undefined
        )[0][currentPortfolioInsightKey]
      : allPerformanceData;
  }

  useEffect(() => {
    const response3 = WrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings-insights`
    )
      .then((response3) => {
        setInsightsData(response3.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    const response5 = WrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings-history?all_portfolios=true`
    )
      .then((response5) => {
        setAllPerformanceData(response5.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    const response8 = WrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings-history?all_portfolios=false`
    )
      .then((response8) => {
        setPerformanceData(response8.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    const response6 = WrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings-chart?all_portfolios=true`
    )
      .then((response6) => {
        setAllAssetData(response6.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    const response7 = WrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings-chart?all_portfolios=false`
    )
      .then((response7) => {
        setAssetData(response7.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [authUser.username]);

  useEffect(() => {
    if (allData[2]) {
      let newArr = [];
      let i = 0;

      let companies = data[0] ? data[0] : allData[2];

      if (data[0]) {
        for (let company in companies) {
          let portfolioInCompany = companies[company];
          for (let keys in portfolioInCompany) {
            newArr.push({
              insightKey: keys,
              label: `${company}: ${portfolioInCompany[keys]['account_details']['account_name']} -  ${portfolioInCompany[keys]['account_details']['account_number']}`,
              value: portfolioInCompany[keys],
            });
          }
        }
      } else {
        companies.forEach((elem) => {
          newArr.push({
            insightKey: null,
            label: `${elem['broker']}: ${elem['account_name']} -  ${elem['account_number']}`,
            value: null,
          });
        });
      }

      setCurrentPortfolioValue({
        insightKey: 'all_portfolios',
        account_details: allData[1],
        account_holdings: allData[0] && allData[0].all_portfolios,
      });

      setCurrentPortfolioLabel('All Portfolios');
      setCurrentPortfolioInsightKey('all_portfolios');

      setPortfolios([
        {
          insightKey: 'all_portfolios',
          label: 'All Portfolios',
          value: {
            account_details: allData[1],
            account_holdings: allData[0] && allData[0].all_portfolios,
          },
        },
        ...newArr,
      ]);
    }
  }, [data, allData]);

  return (
    <Box backgroundColor={darkMode ? '#161616' : '#f4f4f4'}>
      <PageContainerW
        style={{ backgroundColor: darkMode ? '#161616' : '#f4f4f4' }}
      >
        <Box>
          <TopProfile
            profileData={profileData}
            darkMode={darkMode}
            verdeScore={allData[1] && allData[1].portfolio_verde_score}
            data={data}
          />
        </Box>

        <Box mt="10" mb="25px" marginLeft={15}>
          {!showPortfolioSummary && (
            <Flex
              width={200}
              marginLeft={10}
              justifyContent="center"
              alignItems="center"
            >
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => setShowPortfolioSummary(true)}
              >
                <ChevronLeftIcon
                  w="8"
                  h="8"
                  fontSize="large"
                  marginRight={10}
                  color={darkMode ? 'white' : 'black'}
                />
              </div>

              <Select
                className="text-sm max-w-sm h10"
                styles={{
                  ...selectStyles,
                  control: (styles) => ({
                    ...styles,
                    color: darkMode ? 'white' : 'black',
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    fontSize: 18,
                    height: 55,
                    width: 200,
                  }),
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    color: darkMode ? 'white' : 'black',
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    color: darkMode ? 'white' : 'black',
                  }),
                }}
                options={portfolios}
                value={currentPortfolioLabel}
                placeholder={currentPortfolioLabel}
                onChange={(value) => {
                  if (
                    value.label !== 'All Portfolios' &&
                    (value.value === null || value.insightKey === null)
                  ) {
                    showPageLoading(data.length === 0);
                  } else {
                    setCurrentPortfolioLabel(value.label);
                    setCurrentPortfolioValue(value.value);
                    setCurrentPortfolioInsightKey(value.insightKey);
                  }
                }}
              />
            </Flex>
          )}
        </Box>
        <Flex
          //height="76px"
          borderRadius="5px"
          marginBottom="25px"
          overflowX="scroll"
        >
          {mainTabs.map((elem) => {
            return (
              <div
                style={{
                  backgroundColor:
                    tabSelected === elem.title
                      ? `${darkMode ? '#4adf79' : '#01874e'}`
                      : `${darkMode ? 'transparent' : 'rgb(246,248,250)'}`,
                  marginLeft: 8,
                  marginRight: 8,
                  borderRadius: 10,
                  alignItems: 'center',
                  cursor: 'pointer',
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
                onClick={() => setTabSelected(elem.title)}
              >
                <Text
                  style={{
                    color:
                      tabSelected === elem.title
                        ? darkMode
                          ? 'black'
                          : 'white'
                        : darkMode
                        ? 'white'
                        : 'black',
                    fontWeight: tabSelected === elem.title ? 'bold' : 'normal',
                    lineHeight: '35px',
                    fontSize: '17px',
                  }}
                >
                  {elem.title}
                </Text>
              </div>
            );
          })}
        </Flex>

        <div>
          {tabSelected === 'Portfolio' && showPortfolioSummary ? (
            <MobileSummary
              data={{
                insightKey: 'all_portfolios',
                account_details: allData[1],
                account_holdings: allData[0] && allData[0].all_portfolios,
              }}
              indData={data}
              darkMode={darkMode}
              label={currentPortfolioLabel}
              showPortfolioCallback={() => setShowPortfolioSummary(false)}
            />
          ) : tabSelected === 'Portfolio' && !showPortfolioSummary ? (
            <Overview
              data={currentPortfolioValue}
              darkMode={darkMode}
              label={currentPortfolioLabel}
            />
          ) : tabSelected === 'Allocation' ? (
            <AssetClassification
              data={currentPortfolioValue}
              insightsData={insightsData}
              assetData={
                currentPortfolioLabel === 'All Portfolios'
                  ? allAssetData
                  : assetData[currentPortfolioInsightKey]
              }
              currentKey={currentPortfolioInsightKey}
              darkMode={darkMode}
              label={currentPortfolioLabel}
            />
          ) : (
            <PortfolioPerformance darkMode={darkMode} data={performance()} />
          )}
        </div>
      </PageContainerW>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyVerdeRevamp);
