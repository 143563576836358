import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { Text } from '@chakra-ui/react';
import React, { FC, useEffect, useRef, useState, useMemo } from 'react';
import { DefaultTheme, useTheme } from 'styled-components';
import PageContainer from '../../shared/PageContainer';
import { USER_SOCIAL_LINK, PLAID_GENERATE_TOKEN } from '../../../config/default';
import { useParams, Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { showPageLoading } from '../../../actions/coreAction';
import { useHistory } from 'react-router-dom';
import WrappedAxios from '../../../utils/WrappedAxios';
import bookMarkLogoDark from '../../../public/images/bookMarkDark.png';
import bookMarkLogoWhite from '../../../public/images/bookMarkWhite.png';
import VerdeScoreSvg from '../../../public/images/verde-icon.svg';
import { gtag } from '../../../utils/analytics';
import { useAuth } from '../../hocs/AuthProvider';
import MyProfile from './MyProfile';
import AccountSecurity from './AccountSecurity';
import AccountVisibility from './AccountVisibility';
import SocialAccounts from './SocialAccounts';
import { InfoOutlineIcon, TriangleDownIcon } from '@chakra-ui/icons';

type Theme = DefaultTheme & { isDark: boolean};
const themeObject = {
  dark: {
    bg: '#f4f4f4',
    navItemSelectedBg: 'white',
    navItemSelectedBorder: '#01874e',
    navItemColor: '#8c8c8c',
    navItemSelectedColor: '#01874e',
  },
  light: {
    bg: '#f4f4f4',
    navItemSelectedBg: 'white',
    navItemSelectedBorder: '#01874e',
    navItemColor: '#8c8c8c',
    navItemSelectedColor: '#01874e',
  },
};

const PageContainerW: any = PageContainer;
const scrollToElement = (id: string, isMobile: boolean) => {
  var element = document.getElementById(id);
  if (element !== null) {
    var headerOffset = isMobile ? 50 : 100;
    var elementPosition = element!.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

const clickTab = (tabId: string) => {
  const tabElm = document.getElementById(`tab-${decodeURIComponent(tabId)}`);
  if (tabElm) {
    tabElm.dispatchEvent(new Event('click', { bubbles: true }));
  }
};

const inViewChange = (tab: string, state: boolean, sid: string) => {
  if (state) {
    gtag('event', `${tab}-company-scroll`, {
      event_category: tab,
      event_label: sid,
    });
  }
};
const EditProfile: FC<{
  darkMode: any;
  loading: any;
  showPageLoading: any;
  linked:any;
}> = ({ darkMode, loading, showPageLoading, linked }) => {
  const currentTheme: Theme = useTheme() as Theme;
  const [data, setData] = useState({
    id: '',
    profile_pic_url: null,
    name: null,
    email: '',
    description: '',
    username: '',
    followers_count: null,
    followees_count: null,
  });
  const [portfolioData, setPortfolioData] = useState([]);
  const [post, setPost] = useState(false);
  const params: { sid: string } = useParams();
  const theme = currentTheme.isDark ? themeObject.dark : themeObject.light;
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { authUser } = useAuth();

  async function callback(obj: any) {
    console.log(obj);
    const newObj = {
      profile_visibility: obj['profile_visibility']
        ? obj['profile_visibility']
        : 'PRIVATE',
      portfolio_visibility: obj['portfolio_visibility']
        ? obj['portfolio_visibility']
        : 'PRIVATE',
      description: obj['description'],
      name: obj['name'],
      username: obj['username'],
      discord_handle: obj['discord_handle'],
      youtube_handle: obj['youtube_handle'],
      website_url: obj['website_url'],
      twitter_handle: obj['twitter_handle'],
      instagram_handle: obj['instagram_handle'],
      tiktok_handle: obj['tiktok_handle'],
      email: obj['email'],
    };

    const response = await WrappedAxios.put(
      `${USER_SOCIAL_LINK}/users`,
      newObj,
      {
        headers: {
          'Content-Type': `application/json`,
        },
      }
    ).catch((err) => alert(err.message));
  }
  showPageLoading(data.name === null);

  useEffect(() => {
    const response = WrappedAxios.get(
      `${USER_SOCIAL_LINK}/users/${authUser.username}`
    )
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        alert(error.message);
      });

      if(linked)
      {
      const response2 = WrappedAxios.get(
        `${PLAID_GENERATE_TOKEN}/userholdings?all_portfolios=false`
      )
        .then((response2) => {
          setPortfolioData(response2.data);
        })
        .catch((error) => {
          alert(error.message);
        });
      }

  }, [authUser,linked]); //can add post as dependency

  const profile = useMemo(
    () => <MyProfile darkMode={darkMode} data={data} callback={callback} />,
    [darkMode, data]
  );

  const PasswordSecurity = useMemo(
    () => <AccountSecurity darkMode={darkMode} data={data} />,
    [darkMode, data]
  );

  const accountVisibility = useMemo(
    () => (
      <AccountVisibility darkMode={darkMode} portfolioData = {portfolioData} data={data} callback={callback} />
    ),
    [darkMode, data, portfolioData]
  );

  const socialAccounts = useMemo(
    () => (
      <SocialAccounts darkMode={darkMode} data={data} callback={callback} />
    ),
    [darkMode, data]
  );

  const [myProfileRef, myProfileInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('My Profile', inView, params.sid),
  });

  const [passwordSecurityRef, passwordSecurityInView] = useInView({
    threshold: 0.05,
    onChange: (inView) =>
      inViewChange('Password & Security', inView, params.sid),
  });

  const [privacyRef, privacyInView] = useInView({
    threshold: 0.05,
    onChange: (inView) => inViewChange('Privacy Settings', inView, params.sid),
  });

  useEffect(() => {}, []);

  const dupTabs = [
    {
      name: 'My Profile',
      selected: privacyInView ? false : myProfileInView,
      exists: true,
      tabs: [],
    },
    // {
    //   name: 'Password & Security',
    //   selected: privacyInView ? false : passwordSecurityInView,
    //   exists: true,
    //   tabs: [],
    // },
    {
      name: 'Privacy Settings',
      selected: privacyInView,
      exists: true,
      tabs: [],
    },
  ];

  return (
    <Flex
      bg={darkMode ? '#161616' : '#F4F4F4'}
      minH="90vh"
      mt="60px"
      overflowX={isMobile ? 'hidden' : 'visible'}
    >
      <Container maxW="container.xl">
        <Grid
          templateColumns={isMobile ? 'repeat(1, 1fr)' : 'repeat(4, 1fr)'}
          gap={isMobile ? 3 : 4}
          mt="5"
        >
          <GridItem rowSpan={1} colSpan={1}>
            <Box
              as="ul"
              position={isMobile ? 'fixed' : 'sticky'}
              top={isMobile ? '64px' : '100px'}
              marginBottom={isMobile ? '10px' : ''}
              backgroundColor={
                isMobile ? (!darkMode ? '#F4F4F4' : '#161616') : 'transparent'
              }
              zIndex={9}
              width="100%"
            >
              {/* {!myProfileInView && data && (
                <Flex
                  bg={darkMode ? (isMobile ? '#262626' : '#262626') : 'white'}
                  p="3"
                  flexDirection={isMobile ? 'row' : 'column'}
                  borderRadius="5px"
                  marginTop={2}
                  marginBottom="25px"
                >
                  <Flex>
                    <Flex
                      backgroundSize="cover"
                      borderRadius={50}
                      backgroundImage={`${
                        data && data['profile_pic_url'] !== null
                          ? data['profile_pic_url']
                          : VerdeScoreSvg
                      }`}
                      width={isMobile ? 30 : 70}
                      height={isMobile ? 27 : 67}
                    />

                    <Flex
                      flexDirection="column"
                      ml={isMobile ? '2' : '5'}
                      mb={isMobile ? '0' : 2}
                    >
                      <Text
                        fontWeight="bold"
                        color={darkMode ? 'white' : 'black'}
                      >
                        {data['name']}
                      </Text>
                      <Text
                        fontSize="small"
                        color={darkMode ? 'white' : 'black'}
                      >
                        <span
                          style={{
                            textDecoration: 'underline',
                            textDecorationStyle: 'solid',
                            color: '#0072db',
                            textUnderlineOffset: '3px',
                          }}
                        >
                          {data.email}{' '}
                        </span>
                      </Text>
                      <Flex
                        my={isMobile ? '0' : '4'}
                        gap="3"
                        marginLeft={isMobile ? '4' : '0'}
                      >
                        {!isMobile && (
                          <Flex flexDirection="column" marginRight="1">
                            <Text
                              fontWeight="bold"
                              fontSize="xl"
                              color={darkMode ? 'white' : 'black'}
                            >
                              {data.followers_count}
                            </Text>

                            <Flex alignItems="center">
                              <Text
                                color={darkMode ? 'white' : 'black'}
                                fontSize={isMobile ? '12px' : '15px'}
                              >
                                Followers
                              </Text>
                            </Flex>
                          </Flex>
                        )}

                        {!isMobile && (
                          <Flex flexDirection="column">
                            <Flex
                              fontWeight="bold"
                              fontSize="xl"
                              alignItems="center"
                            >
                              <Text color={darkMode ? 'white' : 'black'}>
                                {' '}
                                {data.followees_count}
                              </Text>
                            </Flex>
                            <Flex alignItems="center" gap="1">
                              <Text
                                color={darkMode ? 'white' : 'black'}
                                fontSize={isMobile ? '12px' : '15px'}
                              >
                                Following
                              </Text>
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              )} */}

              <Box
                as="ul"
                top={isMobile ? '64px' : '100px'}
                display={isMobile ? 'flex' : 'block'}
                marginBottom={isMobile ? '10px' : ''}
                backgroundColor={
                  isMobile ? (!darkMode ? '#F4F4F4' : '#161616') : 'transparent'
                }
                zIndex={9}
                width="100%"
                overflowX={isMobile ? 'scroll' : 'hidden'}
              >
                {/* <h1 style={{fontSize:'55px', color:'pink',position:'sticky'}}>{dupTabs[0].name} , {JSON.stringify(dupTabs[0].selected)}</h1> */}

                {dupTabs.map((tab) => (
                  <Box as="li" textAlign={isMobile ? 'center' : 'start'}>
                    <Text
                      p="2"
                      paddingLeft="16px"
                      borderBottom={isMobile && tab.selected ? '4px' : 'none'}
                      borderLeft={tab.selected && !isMobile ? '4px' : 'none'}
                      borderColor={
                        darkMode ? '#4ADF79' : theme.navItemSelectedBorder
                      }
                      minH="30px"
                      bg={
                        tab.selected
                          ? !isMobile
                            ? darkMode
                              ? '#262626'
                              : theme.navItemSelectedBg
                            : 'transparent'
                          : 'transparent'
                      }
                      boxSizing="border-box"
                      fontWeight="bold"
                      fontSize={isMobile ? '11px' : '16px'}
                      color={
                        tab.selected
                          ? darkMode
                            ? 'white'
                            : 'black'
                          : !darkMode
                          ? 'black'
                          : 'white'
                      }
                      width={isMobile ? '140px' : ''}
                    >
                      <div
                        style={{ cursor: 'pointer' }}
                        id={`tab-${tab.name}`}
                        onClick={() => {
                          var element = document.getElementById(`${tab.name}`);
                          if (element !== null) {
                            var headerOffset = isMobile ? 200 : 100;
                            var elementPosition = element!.getBoundingClientRect()
                              .top;
                            var offsetPosition =
                              elementPosition +
                              window.pageYOffset -
                              headerOffset;

                            window.scrollTo({
                              top: offsetPosition,
                              behavior: 'smooth',
                            });
                            gtag('event', `${tab.name}-company-tag-click`, {
                              event_category: tab.name,
                              event_label: params.sid,
                            });
                          }
                        }}
                      >
                        {tab.name}
                      </div>
                    </Text>
                  </Box>
                ))}
              </Box>
            </Box>
          </GridItem>
          <GridItem colSpan={3} bg="" marginTop="20px" marginBottom="20px">
            <Flex flexDirection="column" gap="4">
              {/* --------------------------------------PROFILE---------------------------------- */}
              <div
                ref={myProfileRef}
                style={{ backgroundColor: 'transparent' }}
              >
                {data && (
                  <Flex
                    id="My Profile"
                    borderRadius="5px"
                    p="5"
                    ref={myProfileRef}
                    flexDirection="column"
                    bg={darkMode ? '#262626' : 'white'}
                  >
                    {profile}
                  </Flex>
                )}

                {/* {data && (
                  <Flex
                    id=""
                    borderRadius="5px"
                    p="5"
                    ref={myProfileRef}
                    flexDirection="column"
                    mt={5}
                    bg={darkMode ? '#262626' : 'white'}
                  >
                    {socialAccounts}
                  </Flex>
                )} */}
              </div>
              {/* --------------------------------------PROFILE---------------------------------- */}

              {/* --------------------------------------SECURITY---------------------------------- */}

            
              {/* {data && (
                <Flex
                  id="Password & Security"
                  borderRadius="5px"
                  p="5"
                  ref={passwordSecurityRef}
                  flexDirection="column"
                  bg={darkMode ? '#262626' : 'white'}
                >
                  {PasswordSecurity}
                </Flex>
              )} */}

              {data && (
                <Flex
                  id="Privacy Settings"
                  borderRadius="5px"
                  p="5"
                  ref={privacyRef}
                  flexDirection="column"
                  bg={darkMode ? '#262626' : 'white'}
                >
                  {accountVisibility}
                </Flex>
              )}

              {/* --------------------------------------SECURITY---------------------------------- */}
              
            </Flex>
          </GridItem>
        </Grid>
      </Container>
    </Flex>
  );
};

const mapStateToProps = (state: any) => ({
  linked: state.myVerde.linked,
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
