import React from 'react';
import {
  Box,
  Container,
  Grid,
  GridItem,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { Text } from '@chakra-ui/react';
import Select from 'react-select';
import { useState, FC, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { DefaultTheme, useTheme } from 'styled-components';
import { getMyVerde } from '../../../actions/my-verde';
import { showPageLoading } from '../../../actions/coreAction';
import {
  AI_INSIGHTS_LINK,
  PLAID_GENERATE_TOKEN,
  USER_SOCIAL_LINK,
} from '../../../config/default';
import PageContainer from '../../shared/PageContainer';
import Overview from '../../shared/Sections/MyVerdeRevamp/Overview';
import AssetClassification from '../../shared/Sections/MyVerdeRevamp/AssetClassification';
import TopProfile from '../../shared/Sections/MyVerdeRevamp/TopProfile';
//import  PortfolioModal  from './Modal/PortfolioModal';
import { getSelectStyles } from '../../shared/Sections/DefaultFiltersNew/default-filters';
import { Auth } from 'aws-amplify';
import WrappedAxios from '../../../utils/WrappedAxios';
import { useAuth } from '../../hocs/AuthProvider';
import { useHistory } from 'react-router-dom';
import Analysis from '../../shared/Sections/MyVerdeRevamp/Analysis';
import Fees from '../../shared/Sections/MyVerdeRevamp/Fees';
import Recommendations from '../../shared/Sections/MyVerdeRevamp/Recommendations';
import PortfolioModal from './Modal/PortfolioModal';

const PageContainerW = PageContainer;

const MyVerdeRevamp = ({
  darkMode,
  showPageLoading,
  data,
  allData,
  profileData,
}) => {
  const mainTabs = [
    {
      title: 'Overview',
    },
    {
      title: 'Analysis',
    },
    {
      title: 'Recommendations',
    },
    {
      title: 'Fees',
    },
  ];
  const history = useHistory();
  const { authUser } = useAuth();
  const currentTheme = useTheme();
  const [tabSelected, setTabSelected] = useState(mainTabs[0].title);
  const selectStyles = getSelectStyles(currentTheme.isDark);
  const [insightsData, setInsightsData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [allPerformanceData, setAllPerformanceData] = useState([]);
  const [assetData, setAssetData] = useState({});
  const [allAssetData, setAllAssetData] = useState({});
  const [portfolios, setPortfolios] = useState([]);
  const [currentPortfolioLabel, setCurrentPortfolioLabel] = useState([]);
  const [currentPortfolioValue, setCurrentPortfolioValue] = useState([]);
  const [currentPortfolioInsightKey, setCurrentPortfolioInsightKey] = useState(
    ''
  );
  const [showPortfolioModal, setShowPortfolioModal] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  const [analysisData, setAnalysisData] = useState({});
  const [recommendationsData, setRecommendationsData] = useState({});
  const [feesData, setFeesData] = useState({});

  function performance() {
    if (
      performanceData.length === 0 ||
      currentPortfolioInsightKey === '' ||
      allPerformanceData.length === 0
    ) {
      return [];
    }

    let filteredArr = performanceData.filter(
      (elem) => elem[currentPortfolioInsightKey] !== undefined
    );

    return currentPortfolioInsightKey !== 'all_portfolios'
      ? filteredArr.length > 0
        ? filteredArr[0][currentPortfolioInsightKey]
        : allPerformanceData
      : allPerformanceData;
  }

  useEffect(() => {
    WrappedAxios.get(`${PLAID_GENERATE_TOKEN}/userholdings-insights`)
      .then((response3) => {
        setInsightsData(response3.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    WrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings-history?all_portfolios=true`
    )
      .then((response5) => {
        setAllPerformanceData(response5.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    WrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings-history?all_portfolios=false`
    )
      .then((response8) => {
        setPerformanceData(response8.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    WrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings-chart?all_portfolios=true`
    )
      .then((response6) => {
        setAllAssetData(response6.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    WrappedAxios.get(
      `${PLAID_GENERATE_TOKEN}/userholdings-chart?all_portfolios=false`
    )
      .then((response7) => {
        setAssetData(response7.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    WrappedAxios.post(`${AI_INSIGHTS_LINK}/verde-ai-insights`, {
      page_name: 'fees',
    })
      .then((response7) => {
        setFeesData(response7.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    WrappedAxios.post(`${AI_INSIGHTS_LINK}/verde-ai-insights`, {
      page_name: 'recommendations',
    })
      .then((response7) => {
        setRecommendationsData(response7.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    WrappedAxios.post(`${AI_INSIGHTS_LINK}/verde-ai-insights`, {
      page_name: 'analysis',
    })
      .then((response7) => {
        setAnalysisData(response7.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
    WrappedAxios.post(`${AI_INSIGHTS_LINK}/verde-ai-insights`, {
      page_name: 'overview',
    })
      .then((response7) => {
        setOverviewData(response7.data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, [authUser.username]);

  useEffect(() => {
    if (allData[2]) {
      let newArr = [];
      let i = 0;

      let companies = data[0] ? data[0] : allData[2];

      if (data[0]) {
        for (let company in companies) {
          let portfolioInCompany = companies[company];
          for (let keys in portfolioInCompany) {
            newArr.push({
              insightKey: keys,
              label: `${company}: ${portfolioInCompany[keys]['account_details']['account_name']} -  ${portfolioInCompany[keys]['account_details']['account_number']}`,
              value: portfolioInCompany[keys],
            });
          }
        }
      } else {
        companies.forEach((elem) => {
          newArr.push({
            insightKey: null,
            label: `${elem['broker']}: ${elem['account_name']} -  ${elem['account_number']}`,
            value: null,
          });
        });
      }

      setCurrentPortfolioValue({
        insightKey: 'all_portfolios',
        account_details: allData[1],
        account_holdings: allData[0] && allData[0].all_portfolios,
      });

      setCurrentPortfolioLabel('All Portfolios');
      setCurrentPortfolioInsightKey('all_portfolios');

      setPortfolios([
        {
          insightKey: 'all_portfolios',
          label: 'All Portfolios',
          value: {
            account_details: allData[1],
            account_holdings: allData[0] && allData[0].all_portfolios,
          },
        },
        ...newArr,
      ]);
    }
  }, [data, allData]);

  return (
    <Box backgroundColor={darkMode ? '#161616' : '#f4f4f4'}>
      <PageContainerW
        style={{ backgroundColor: darkMode ? '#161616' : '#f4f4f4' }}
      >
        {/* <Box>
          <TopProfile
            profileData={profileData}
            darkMode={darkMode}
            verdeScore={allData[1] && allData[1].portfolio_verde_score}
          />
        </Box> */}
        {showPortfolioModal && (
          <PortfolioModal
            data={data} //data is ind. portfolios
            darkMode={darkMode}
            closeModal={() => setShowPortfolioModal(false)}
            profileData={profileData}
            onUnlink={(chosenUnlinkPortfolio) => {
              // window.location.reload();
              // let filteredArr = portfolios.filter(
              //   (elem) => elem.insightKey !== chosenUnlinkPortfolio.portfolioId
              // );
              //   else
              //   {
              //   setPortfolios([...filteredArr])
              //   setCurrentPortfolioValue({
              //     insightKey: 'all_portfolios',
              //     account_details: allData[1],
              //     account_holdings: allData[0] && allData[0].all_portfolios,
              //   });
              //   setCurrentPortfolioLabel('All Portfolios');
              //   setCurrentPortfolioInsightKey('all_portfolios');
              // }
            }}
          />
        )}
        <Box mt="6rem" mb="25px">
          <Flex>
            <Flex>
              <Text
                style={{
                  marginRight: 30,
                  color: darkMode ? '#cccccc' : '#8c8c8c',
                  fontSize: '20px',
                }}
              >
                Portfolio
              </Text>
              <div style={{ width: '250px' }}>
                <Select
                  className="text-sm max-w-sm"
                  styles={selectStyles}
                  options={portfolios}
                  value={currentPortfolioLabel}
                  placeholder={currentPortfolioLabel}
                  onChange={(value) => {
                    if (
                      value.label !== 'All Portfolios' &&
                      (value.value === null || value.insightKey === null)
                    ) {
                      showPageLoading(data.length === 0);
                    } else {
                      setCurrentPortfolioLabel(value.label);
                      setCurrentPortfolioValue(value.value);
                      setCurrentPortfolioInsightKey(value.insightKey);
                    }
                  }}
                />
              </div>
            </Flex>
            <Box marginLeft="auto">
              <button
                style={{
                  padding: 8,
                  width: '150px',
                  color: darkMode ? '#4adf79' : '#01874e',
                  border: `1px solid ${darkMode ? '#4adf79' : '#01874e'}`,
                  borderRadius: 20,
                  fontSize: 15,
                  fontWeight: 500,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => setShowPortfolioModal(true)}
              >
                Manage Portfolio
              </button>
            </Box>
          </Flex>
        </Box>
        <Flex
          //height="76px"
          borderRadius="5px"
          backgroundColor={darkMode ? '#262626' : 'white'}
          marginBottom="25px"
        >
          {mainTabs.map((elem) => {
            return (
              <div
                style={{
                  borderBottom:
                    tabSelected === elem.title
                      ? `3px solid ${darkMode ? '#4adf79' : '#01874e'}`
                      : '',
                  marginLeft: 20,
                  marginRight: 15,
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: 15,
                }}
                onClick={() => setTabSelected(elem.title)}
              >
                <Text
                  style={{
                    color:
                      tabSelected === elem.title
                        ? darkMode
                          ? '#4adf79'
                          : '#01874e'
                        : darkMode
                        ? '#cccccc'
                        : '#8c8c8c',
                    fontWeight: tabSelected === elem.title ? 'bold' : 'normal',
                    lineHeight: '35px',
                    fontSize: '17px',
                  }}
                >
                  {elem.title}
                </Text>
              </div>
            );
          })}
        </Flex>

        <div>
          {tabSelected === 'Overview' ? (
            <Overview
              data={currentPortfolioValue}
              darkMode={darkMode}
              insightKey={currentPortfolioInsightKey}
              overviewData={overviewData}
              analysisData={analysisData}
              setTabSelected={setTabSelected}
            />
          ) : tabSelected === 'Analysis' ? (
            <Analysis
              darkMode={darkMode}
              data={analysisData}
              label={currentPortfolioLabel}
              chartData={performance()}
              insightKey={currentPortfolioInsightKey}
              assetData={
                currentPortfolioLabel === 'All Portfolios'
                  ? allAssetData
                    ? allAssetData
                    : []
                  : assetData
                  ? assetData[currentPortfolioInsightKey]
                  : []
              }
              insightsData={insightsData}
            />
          ) : tabSelected === 'Recommendations' ? (
            <Recommendations darkMode={darkMode} data={recommendationsData} />
          ) : (
            <Fees darkMode={darkMode} data={feesData} />
          )}
        </div>
      </PageContainerW>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  darkMode: state.coreState.darkMode,
  loading: state.summaryState.data,
});

const mapDispatchToProps = {
  showPageLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyVerdeRevamp);
